import { Link } from "@app/components/Link"
import { withSection } from "@app/hoc/Section"
import { useImage } from "@app/hooks/useImage"
import { useSanityColour } from "@app/hooks/useSanity"
import { useAppContext } from "@app/providers/app"
import { AspectRatio, Box, HStack, Image, Text, VStack, Heading } from "@chakra-ui/react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import type { ScrollPositionProps, Section } from "@root/types/global"
import React, { memo, useEffect, useMemo, useState } from "react"

type Props = Section & GatsbyTypes.SanitySectionFeaturedStores

const FeaturedStores: React.FC<Props> = withSection(
  memo(({ title, stores: rawStores, link, image, isHomepage, index, handleTrackingClick }) => {
    const [active] = useState<number>(0)
    const [position, setPosition] = useState<number>(0)
    const { dispatch, state } = useAppContext()
    const { getGatsbyImage } = useImage()

    const stores = useMemo(
      () =>
        rawStores?.map(store => ({
          ...store,
          key: store._key,
        })),
      [rawStores]
    )

    const activeItem = useMemo(() => stores?.[active], [active, stores])

    const { background, colour } = useSanityColour({
      themeColourBackground: { theme: activeItem?.headerBackground },
      themeColourText: { theme: activeItem?.headerText },
    })

    useScrollPosition(({ currPos }: ScrollPositionProps) => setPosition(currPos?.y), [], undefined, true, 100)

    useEffect(() => {
      const visible = position < 35

      const activeBackground = background && visible ? background : ""
      const activeInversion = colour === "white" ? visible : false

      if ((state.activeInversion !== activeInversion || state.activeBackground !== activeBackground) && !index && isHomepage)
        dispatch({
          type: "theme",
          payload: {
            activeBackground,
            activeInversion,
          },
        })
    }, [background, colour, dispatch, isHomepage, index, position, state])

    const imageDesktop = getGatsbyImage(image?.desktop, { width: 2000 })
    const imageMobile = getGatsbyImage(image?.mobile?.asset ? image?.mobile : image?.desktop, { width: 1000 })
    const desktopImageWidth = imageDesktop?.dimensions?.width
    const desktopImageHeight = imageDesktop?.dimensions?.height
    const mobileImageWidth = imageMobile?.dimensions?.width
    const mobileImageHeight = imageMobile?.dimensions?.height

    return stores?.length > 0 ? (
      <Box
        as={link?.url ? Link : "div"}
        bg={background}
        color={colour}
        display="block"
        onClick={handleTrackingClick}
        title={link?.url ? link?.title || title : ""}
        aria-label={link?.url ? link?.title || title : ""}
        paddingTop={6}
        paddingBottom={6}
      >
        <AspectRatio
          ratio={{
            base: mobileImageWidth / mobileImageHeight || 1,
            md: desktopImageWidth / desktopImageHeight || 1,
          }}
        >
          <>
            {imageDesktop && (
              <Image
                {...imageDesktop}
                alt={imageDesktop?.alt || title || ""}
                display={{ base: "none !important", sm: isHomepage ? "none !important" : "flex !important", md: "flex !important" }}
                maxWidth={{ base: "100%", lg: "100%" }}
                maxHeight={{ base: "100%", lg: "100%" }}
              />
            )}
            {imageMobile && (
              <Image
                {...imageMobile}
                alt={imageMobile?.alt || title || ""}
                display={{ base: "flex !important", sm: isHomepage ? "flex !important" : "none !important", md: "none !important" }}
                maxWidth={{ base: "100%", lg: "100%" }}
                maxHeight={{ base: "100%", lg: "100%" }}
                left={{ base: "0% !important", lg: "50% !important" }}
              />
            )}
          </>
        </AspectRatio>
        <Box textAlign="start" width="100%" top={"auto"} padding={["32px 24px", "32px 56px"]}>
          <HStack
            direction={{ base: "column", md: "row" }}
            spacing={0}
            width="100%"
            alignItems={{ sm: "block", md: "flex" }}
            display={{ sm: "block", md: "flex" }}
          >
            <Heading as="p" size="mainHeading" alignSelf="flex-start" minWidth="208px">
              {title}
            </Heading>
            <Box display={{ base: "flex" }} overflowX="auto" maxWidth="100%" paddingTop={{ base: 4, md: 0 }}>
              {stores.map((store, idx) => (
                <VStack key={store.key} spacing={0} align="flex-start">
                  <Box minWidth="208px" paddingLeft={idx !== 0 ? 4 : 0}>
                    <Text fontWeight={500} lineHeight={1.5} color="grey.coal">
                      {store?.title}
                    </Text>
                    <Link
                      to={`https://maps.google.com/?q=${store.title},${store.address.street},${store.address.city},${store.location.lat},${store.location.lng}`}
                      fontWeight={500}
                      lineHeight={1.5}
                      color="grey.coal"
                      textDecoration="underline"
                    >
                      {store.address.street}
                    </Link>
                    <Text fontWeight={500} lineHeight={1.5} color="grey.coal">
                      {store.address.city}
                    </Text>
                    <Link to={`tel:${store?.phone}`} fontWeight={500} lineHeight={1.5} color="grey.coal" textDecoration="underline">
                      {store?.phone ? store.phone : "04123456789"}
                    </Link>
                  </Box>
                </VStack>
              ))}
            </Box>
            <Box
              display={{ base: "flex" }}
              alignSelf={{ base: "flex-end" }}
              paddingLeft={{ base: 0, md: 4 }}
              paddingTop={{ base: 4, md: 0 }}
            >
              {link?.title && (
                <Link
                  to={link.document.url}
                  size="underline"
                  variant="underline"
                  title={link.title}
                  aria-label={link.title}
                  color="grey.coal"
                  borderColor="grey.coal"
                  borderBottom="2px"
                  lineHeight={1.5}
                  fontWeight={700}
                  letterSpacing={2.532}
                  fontSize={{ base: 12.66, md: 14 }}
                  textAlign={"center"}
                >
                  {link.title}
                </Link>
              )}
            </Box>
          </HStack>
        </Box>
      </Box>
    ) : null
  })
)

export default FeaturedStores
